import IMask from 'imask'
import {BASE_URL, initMatch, OWNER} from "./api";
import {popupScreenHandler} from "./popup-utils";

const popupForm = document.querySelector('.popup-dark--form')
const form = popupForm.querySelector('.form')
const counterScreen = form.querySelector('.form__wrapper--counter')
const contactScreen = form.querySelector('.form__wrapper--contact')
const resultScreen = form.querySelector('.form__wrapper--result')
const repeatScreen = form.querySelector('.form__wrapper--repeat')

const first = form.querySelector('.form__counter--first')
const second = form.querySelector('.form__counter--second')
const firstScoreElement = first.querySelector('.form__input-count')
const secondScoreElement = second.querySelector('.form__input-count')
const btnCounter = form.querySelector('.form__btn--counter')
const btnContact = form.querySelector('.form__btn--contact')
const telInput = form.querySelector('.form__input-tel')
const checkBoxInput = form.querySelector('.form__agree')
const expiedScreen = document.querySelector('.form__wrapper--timer-expired')

const scores = []

const updateIMaskValues = () => {
    scores.forEach(input => {
        input.updateValue()
    })
}

const checkValue = (evt) => {
    const target = evt.target
    if (target.value === '0') {
        target.value = ''

    } else if (target.value === '') {
        target.value = '0'
    }

    updateIMaskValues()
}

const addCounterHandlers = (wrapper) => {
    const inc = wrapper.querySelector('.form__button--inc')
    const dec = wrapper.querySelector('.form__button--dec')
    const input = wrapper.querySelector('.form__input-count')

    inc.addEventListener('click', evt => {
        evt.preventDefault()
        ++input.value
    })

    dec.addEventListener('click', evt => {
        evt.preventDefault()
        input.value > 0 ? --input.value : null
    })

    scores.push(IMask(
        input, {
            mask: '00',
            lazy: true,
            autofix: true
        }
    ))

    input.addEventListener('focus', checkValue)
    input.addEventListener('focusout', checkValue)
}

btnCounter.addEventListener('click', evt => {
    evt.preventDefault()
    popupScreenHandler(counterScreen, contactScreen)
    contactScreen.querySelector('input').focus()
})

addCounterHandlers(first)
addCounterHandlers(second)

const forecastSubmitHandler = (evt) => {
    evt?.preventDefault()

    const firstScore = firstScoreElement.value
    const secondScore = secondScoreElement.value
    const phone = telInput.value.replace(/[^0-9]/g, "")

    const formData = new FormData();
    if (initMatch.isReverseCommand) {
        if (initMatch.commandGuestIsOwner) {
            formData.append('fan_forecast_command_2', firstScore + '')
            formData.append('fan_forecast_command_1', secondScore + '')
        } else {
            formData.append('fan_forecast_command_2', firstScore + '')
            formData.append('fan_forecast_command_1', secondScore + '')
        }
    } else {
        formData.append('fan_forecast_command_1', firstScore + '')
        formData.append('fan_forecast_command_2', secondScore + '')
    }

    formData.append('phone_number', phone + '');
    formData.append('confirmation', '1');
    formData.append('match', initMatch.id);
    formData.append('landing', OWNER);

    const requestOptions = {
        method: 'POST',
        body: formData,
    };

    fetch(`${BASE_URL}/api/forecast-contests/create`, requestOptions)
        .then(response => {
            if (response.status === 200)
                return response
            else if (response.status === 400)
                return response
            else (response.status === 201)
            return false
        })
        .then(result => {
            if (result) {
                switch (result.status) {
                    case 200: {
                        popupScreenHandler(contactScreen, resultScreen)
                        break;
                    }
                    case 400: {
                        popupScreenHandler(contactScreen, expiedScreen)
                        break;
                    }
                }
            } else {
                popupScreenHandler(contactScreen, repeatScreen)
            }
        })
        .catch(error => console.log('error', error));
}

form.addEventListener('submit', forecastSubmitHandler)

//-----------------------------------------------------------------------------------------------
const inputPhone = form.querySelector('input[type="tel"]')

IMask(
    inputPhone, {
        mask: '+{7}({9}00) 000-00-00',
    }
);

inputPhone.setCustomValidity('Пожалуйста введите номер телефона')

// todo: перенести
export const isValidPhone = (phoneValue) => {

    let valuePhone = phoneValue.value.trim()

    const errValidPhone = () => {
        inputPhone.setCustomValidity('Пожалуйста введите номер телефона')
        inputPhone.classList.add('err')
    }
    if (valuePhone.search(/[a-z, A-Z]/) !== -1) {
        if (valuePhone.length < 17) {
            return errValidPhone()
        }
    }
    if (valuePhone[valuePhone.length - 1].search(/[!@#$%^&*()\-=_+~[\]{}''`\\|,.;:/<>?]/) !== -1) {
        return errValidPhone()
    }
    if (valuePhone.length < 16) {
        return errValidPhone()
    }
    inputPhone.setCustomValidity('')
    inputPhone.classList.remove('err')
    return true
}


inputPhone.addEventListener('input', () => {
    isValidPhone(inputPhone)
})

inputPhone.addEventListener('keypress', (evt) => {
    if (evt.keyCode === 13) {
        btnContact.click()
    }
})
