const body = document.querySelector('body')
const popupsOverlays = document.querySelectorAll ('.popup-dark__overlay')
const popups = document.querySelectorAll('.popups')
const btnClose = document.querySelectorAll('.popup-dark__btn-close')

popupsOverlays.forEach(overlay => {
    overlay.addEventListener('click', () => {
        closePopup()
    })
})

const popupScrollTop = () => {
    popupsOverlays.forEach(popup => {
        popup.scrollIntoView({block: 'start'})
    })
}

export const popupScreenHandler = (thisScreen, nextScreen) => {
    thisScreen.classList.add('visually-hidden')
    nextScreen.classList.remove('visually-hidden')
    popupScrollTop()
}

export const openPopup = (popup) => {
    popup.classList.add('popup-dark--active')
    // body.classList.add('no-scroll')
}

export const closePopup = () => {
    popups.forEach(popup => {
        popup.classList.remove('popup-dark--active')
    })

    // body.classList.remove('no-scroll')
}

btnClose?.forEach(btn=> {
    btn.addEventListener('click', evt => {
        evt.preventDefault()
        closePopup()
    })
})
