const body = document.querySelector('body');
const popups = document.querySelectorAll('.popup-promotion-end');

const isEscapeClosePopup = (event) => {
  if (event.key === 'Escape') {
    closePopups();
  }
};

const closePopups = () => {
  popups.forEach((popup) => {
    popup.classList.remove('popup-promotion-end--open');
    popup.classList.add('popup-promotion-end--close');
  });
  body.classList.remove('no-scroll');
  document.removeEventListener('keydown', isEscapeClosePopup);
};

const openPopups = () => {
  popups.forEach((popup) => {
    popup.classList.add('popup-promotion-end--open');
    popup.classList.remove('popup-promotion-end--close');
  });
  body.classList.add('no-scroll');
  document.addEventListener('keydown', isEscapeClosePopup);
};

popups.forEach((popup) => {
  let buttonClosePopup = popup.querySelector('.popup-promotion-end__close-button');
  buttonClosePopup.addEventListener('click', closePopups);

  popup.addEventListener('click', (event) => {
    const target = event.target.closest('.popup-promotion-end__content');
    if (!target) {
      closePopups();
    }
  });
});

document.addEventListener('DOMContentLoaded', function () {
  document.getElementById('popup-end').addEventListener('click', openPopups);

  document.addEventListener('keydown', isEscapeClosePopup);
});
