import {BASE_URL, OWNER} from "./api";

const winners = document.querySelector('.winners')
const btnAsideWinnersBtn = document.querySelector('.winners-btn')
const matchesWrapper = winners.querySelector('.winners__wrapper--list')
const matchWrapper = winners.querySelector('.winners__wrapper--match')
const matchName = winners.querySelector('.winners__match-name')
const btnBack = matchWrapper.querySelector('.winners__btn-back')
const matchesTemplate = document.querySelector('#winners-item')

const getMatches = () => fetch(`${BASE_URL}/api/forecast-contest-match?landing=${OWNER}`, {
    method: 'GET',
})
    .then(response => {
        if (response.status === 200) {
            return response.json()
        } else {
            throw 'Список матчей не получен'
        }
    })
    .then(result => {
        result.length ?
            appendListHandler(result, matchesWrapper, createMatchListItem) :
            btnAsideWinnersBtn.classList.add('visually-hidden')
    })

getMatches()

const getMatch = (id) => {
    return fetch(`${BASE_URL}/api/winners-lists?match_id=${id}&landing=${OWNER}`, {
        method: 'GET',
        redirect: 'follow'
    })
        .then(response => {
            if (response.status === 200) {
                return response.json()
            } else {
                throw 'Нет данных по матчу'
            }
        })
}

export const matchListActivate = () => {
    matchWrapper.classList.remove('is-active')
    matchesWrapper.classList.add('is-active')
}

const getMatchesList = (wrapper) => {
    const list = wrapper.querySelector('.winners__list')
    const listItems = list.querySelectorAll('li')
    listItems.forEach(item => item.remove())

    return list
}


const createMatchListItem = (item, list) => {
    const listElement = document.createElement('li')
    listElement.append(matchesTemplate.content.cloneNode(true));
    listElement.classList.add('winners__item')
    listElement.dataset.id = item.id
    const name = listElement.querySelector('.winners__name')
    const info = listElement.querySelector('.winners__info')
    const start = new Date(item.start_match.replace(/-/g, "/")).toLocaleDateString().slice(0, -5)
    name.textContent = item.name
    info.textContent = start
    list.append(listElement);

    listElement.addEventListener('click', showMatchHandle)
    listElement.addEventListener('touchEnd', showMatchHandle)
}

const createWinnersListItem = (item, list) => {
    const listElement = document.createElement('li')
    listElement.append(matchesTemplate.content.cloneNode(true));
    listElement.classList.add('winners__item')
    const name = listElement.querySelector('.winners__name')
    const info = listElement.querySelector('.winners__info')
    name.textContent = item.phone_number
    info.textContent = item.what_gets
    list.append(listElement);
}

const appendListHandler = (data, wrapper, createList) => {
    const list = getMatchesList(wrapper)

    data.forEach(item => {
        createList(item, list)
    })
}

const updateMatchName = (target) => {
    const name = target.querySelector('.winners__name')
    const info = target.querySelector('.winners__info')

    matchName.textContent = name.textContent + ' ' + info.textContent
}

export const showMatches = () => {
    matchListActivate()
}

export const showMatchHandle = (evt) => {
    evt.preventDefault()
    const target = evt.target
    const id = target.dataset.id
    updateMatchName(target)

    getMatch(id).then(result => {
        appendListHandler(result, matchWrapper, createWinnersListItem)
    })

    matchesWrapper.classList.remove('is-active')
    matchWrapper.classList.add('is-active')
}

btnBack.addEventListener('click', matchListActivate)
