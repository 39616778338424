import {initMatch, OWNER} from "./api";

export const setCommands = (data) => {
    if (data.landing_2) {

        if (initMatch.isReverseCommand) {
            initMatch.commandOwner = data.landing_2
            initMatch.commandGuest = data.landing_1
        } else {
            initMatch.commandOwner = data.landing_1
            initMatch.commandGuest = data.landing_2
        }

        if (data.landing_2 === OWNER) {
            initMatch.commandGuestIsOwner = true
        }
    } else {
        if (initMatch.isReverseCommand) {
            initMatch.commandOwner = data.landing_custom_name
            initMatch.commandGuest = OWNER
        } else
            initMatch.commandGuest = data.landing_custom_name
    }

}
