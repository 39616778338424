const links = document.querySelectorAll('a:not([href^="#"])')
const queries = window.location.search.replace('?', '&')

links.forEach((link) => {
  link.addEventListener('click', (event) => {
    event.preventDefault()
    const query =
      (event.currentTarget.href.search(/\?/g) > 0 ? '&' : '?') +
      'partner=pb' +
      queries

    const link = event.currentTarget.href + query

    if (event.currentTarget.href) {
      if (event.currentTarget.target === '_blank') {
        window.open(link, '_blank')
      } else {
        window.location.href = link
      }
    }
  })
})

const counterWrapper = document.querySelector('.form__wrapper--counter')
const btnCounter = counterWrapper.querySelector('.form__btn--counter')
const contactWrapper = document.querySelector('.form__wrapper--contact')
const btnContact = contactWrapper.querySelector('.form__btn--contact')
const btnInput = contactWrapper.querySelector('.form__input-tel')

btnCounter.addEventListener('click', () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({'event':'landing',
    'eventCategory':'participation',
    'eventAction':'participation_score_sent_button',
    'eventLabel':'{{Page URL}}'
  })
})

btnContact.addEventListener('click', () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({'event':'landing',
    'eventCategory':'participation',
    'eventAction':'participation_id_sent_button',
    'eventLabel':'{{Page URL}}'
  })
})

btnInput.addEventListener('click', () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({'event':'landing',
    'eventCategory':'participation',
    'eventAction':'participation_field_phone',
    'eventLabel':'{{Page URL}}'
  })
})