// import {startTimer} from "./timer";
import {setCommands} from "./set-commands";

export const OWNER = 'Нефтехимик'
export const BASE_URL = 'https://forecast-contests.pari-api.ru'

export const initMatch = {
    commandOwner: OWNER,
    commandGuest: 'Команда неизвестна',
    matchDate: '',
    nowDate: '',
    id: null,
    isReverseCommand: false,
    commandGuestIsOwner: false,
}

const commandNamesOwner = document.querySelectorAll('.match-name--owner')
const commandNamesGuest = document.querySelectorAll('.match-name--guest')

const matchHandler = () => {
    fetch(`${BASE_URL}/api/forecast-contest-command-match?landing=${OWNER}`, {
        method: 'GET',
    })
        .then((response) => {
            if (response.status === 200) {
                return response.json()
            } else {
                throw 'Данные по матчу не получены'
            }
        })
        .then(result => {
            initMatch.isReverseCommand = result.match.is_reverse_command
            setCommands(result.match, OWNER)
            initMatch.id = result.match.id
            initMatch.matchDate = new Date(result.match.start_match.replace(/-/g, "/"))
            initMatch.nowDate = new Date(result.match.now.replace(/-/g, "/"))

            // startTimer()

            commandNamesOwner.forEach(el => {
                el.textContent = `${initMatch.commandOwner}`
            })
            commandNamesGuest.forEach(el => {
                el.textContent = `${initMatch.commandGuest}`
            })
        })
        .catch(error => console.log('Error ' + error))
}

matchHandler()
