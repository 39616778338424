import IMask from 'imask'
import {BASE_URL, OWNER} from "./api";
import {closePopup, openPopup, popupScreenHandler} from "./popup-utils";
import {isValidPhone} from "./counter";

const popupForecast = document.querySelector('.popup-dark--forecast')
const form = popupForecast.querySelector('.form')
const contactScreen = form.querySelector('.form__wrapper--contact')
const resultScreen = form.querySelector('.form__wrapper--result')
const telInput = form.querySelector('.form__input-tel')
const inputPhone = form.querySelector('input[type="tel"]')
export const winnersPopupForecastResult = document.querySelector('.popup-dark--forecast-result')
export const winnersPopupForecastError = document.querySelector('.popup-dark--forecast-error')
const forecastWrapper = document.querySelector('.winners__wrapper--forecast')
const forecastTemplate = document.querySelector('#forecast-item')

const forecastSubmitHandler = (evt) => {
    evt?.preventDefault()

    const phone = telInput.value.replace(/[^0-9]/g, "")

    const requestOptions = {
        method: 'GET',
        redirect: 'follow'

    };

    fetch(`${BASE_URL}/api/result-forecasts/?phone=${phone}&landing=${OWNER}`, requestOptions)
        .then(response => {
            if (response.status === 200)
                return response.json()
            else
                throw new Error('Данные не найдены')
        })
        .then(result => {
            closePopup()
            openPopup(winnersPopupForecastResult)
            appendListHandler(result, forecastWrapper, createForecastList)
        })
        .catch(error => {
            closePopup()
            openPopup(winnersPopupForecastError)
            console.log('error', error)
        });
}

IMask(
    inputPhone, {
        mask: '+{7}({9}00) 000-00-00',
    }
)

inputPhone.addEventListener('input', () => {
    isValidPhone(inputPhone)
})

form.addEventListener('submit', forecastSubmitHandler)


const getMatchesList = (wrapper) => {
    const list = wrapper.querySelector('.forecast__list')
    const listItems = list?.querySelectorAll('li')
    listItems?.forEach(item => {
        if (item.classList.contains('forecast__item--main') !== true)
            item.remove()
    })

    return list
}


const appendListHandler = (data, wrapper, createList) => {
    const list = getMatchesList(wrapper)

    data.forEach(item => {
        createList(item, list)
    })
}

const createForecastList = (item, list) => {
    const listElement = document.createElement('li')
    listElement?.append(forecastTemplate.content.cloneNode(true));

    listElement.classList.add('forecast__item')
    listElement.dataset.id = item.id
    const name = listElement.querySelector('.forecast__name')
    const nameFirst = name.querySelector('.forecast__name-first')
    const nameSecond = name.querySelector('.forecast__name-second')
    const info = listElement.querySelector('.forecast__info')
    const data = listElement.querySelector('.forecast__data')
    const start = new Date(item.date_match.replace(/-/g, "/")).toLocaleDateString().slice(0, -5)

    info.textContent = `${item.fan_forecast_command_1}:${item.fan_forecast_command_2}`
    nameFirst.textContent = item.match_1
    nameSecond.textContent = item.match_2
    data.textContent = `${start}`

    list?.append(listElement);
}